@import "~@ui/styles/tools";

.container {
    margin-top: 15px;
    margin-bottom: 30px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;

    @include media-laptop {
        grid-template-columns: repeat(3, 1fr);
    }

    @include media-mobile {
        display: flex;
        flex-direction: column;
    }
}