@import "~@ui/styles/variables";
@import "~@ui/styles/tools";
@import "@app/styles/variables";

.section {
    background: $color-off-white;
    padding: 4% 8%;
}

.feature {
    display: flex;
    gap: 60px;

    @include media-tablet-portrait {
        gap: 30px;
        margin-top: 30px;
    }

    @include media-mobile {
        gap: 40px;
        margin-top: 40px;
        flex-direction: column-reverse;
        align-items: center;
    }

    &--title {
        color: $color-title-lighter;
        font-size: 48px;
        font-weight: 600;
        margin: 0;
        max-width: 32ch;

        @include media-desktop-xm {
            font-size: 40px;
        }

        @include media-table-large {
            font-size: 32px;
        }

        @include media-tablet {
            font-size: 24px;
        }

        @include media-tablet-portrait {
            display: none;
        }

        &-mobile {
            display: none;

            @include media-tablet-portrait {
                font-size: 26px;
                display: block;
            }
        }
    }

    &--content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 60px;
        flex: 1;

        @include media-tablet-portrait {
            gap: unset;
        }
    }

    &--chart {
        width: 100%;
        height: auto;
        max-width: 450px;

        @include media-tablet-portrait {
            max-width: 300px;
        }
    }
}

.chart-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 75%;
    gap: 20px;

    &__column {
        flex-direction: column;
    }

    @include media-desktop-md {
        width: 100%;
    }
    @include media-laptop {
        max-width: 300px;
    }

    @include media-mobile {
        max-width: unset;
    }
}

.chart-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    width: 40%;
    min-width: 250px;

    @include media-laptop {
        width: 100%;
    }

    @include media-mobile {
        max-width: unset;
    }

    &__title {
        display: flex;
        gap: 12px;
        align-items: center;
    }

    &__color {
        min-width: 20px;
        height: 20px;
        border-radius: 50%;

        @include media-mobile {
            min-width: 16px;
            height: 16px;
        }
    }

    &__label {
        font-size: 20px;
        line-height: 1.2;
        font-weight: 500;
        color: #1E2021;
        opacity: .6;
        margin: 0;

        @include media-mobile {
            font-size: 16px;
        }
    }

    &__value {
        font-size: 20px;
        font-weight: 500;
        line-height: 1.2;
        color: #1E2021;
        opacity: .6;
        margin: 0;
        white-space: nowrap;

        @include media-mobile {
            font-size: 16px;
        }
    }

    &__bold {
        font-weight: 600;
        opacity: unset;
    }
}