@import "~@ui/styles/tools";
@import "../../../../styles/variables";

@mixin medium-breakpoint {
    @media screen and (max-width: 1650px) {
        @content;
    }
}

@mixin small-breakpoint {
    @media screen and (max-width: 1280px) {
        @content;
    }
}

.feature {
    padding: 4% 8%;
    background-color: $color-off-white;
}

.feature-title {
    font-size: 48px;
    font-weight: 600;
    max-width: 18ch;
    margin-bottom: 32px;
}

.history {
    display: grid;
    grid-template-columns: repeat(12, 3fr);
    grid-template-rows: 1fr min-content 1fr;
    gap: 48px 40px;
}

.slider-wrapper {
    display: none;
}

.slider {
    display: none;
}

.history-line {
    position: relative;

    height: 69px;
    width: 100%;
    grid-row: 2 / 3;
    grid-column: 1 / -1;

    border: 2px solid #dfdfdf;
    border-radius: 0 50px 50px 0;

    border-left: none;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: #dfdfdf;
        border: 7px solid #fff;

        transform: translateX(-50%) translateY(calc(-1 * 50% - 1px));
    }

    &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: #dfdfdf;
        border: 7px solid #fff;

        transform: translateX(-50%) translateY(calc(50% + 1px));
    }
}

.line-tale {
    position: absolute;
    left: 0;
    bottom: 0;
    transform: translateY(calc(50% + 1px)) translateX(-100%);
    height: 2px;
    width: 250px;
    background: linear-gradient(89.75deg, #FFFFFF 49.16%, #dfdfdf 99.82%);
}

@include medium-breakpoint {
    .feature-title {
        font-size: 40px;
    }
}

@include small-breakpoint {
    .feature-title {
        font-size: 32px;
    }
}

@include media-tablet {
    .feature {
        padding: 4% 16px;
    }

    .feature-title {
        font-size: 24px;
    }

    .history {
        display: none;
    }

    .slider-wrapper {
        display: block;

        height: 235px;
    }

    .slider {
        display: block;
        height: 185px;
    }
}

@include media-mobile {
    .feature-title {
        font-size: 26px;
    }
}