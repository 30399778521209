@import "~@ui/styles/tools";

.container {
    display: grid;
    gap: 24px;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-template-areas:
        "calculator calculator chart chart chart"
        "calculator calculator chart chart chart"
        "calculator calculator chart chart chart";

    @include media-tablet {
        gap: 12px;
    }

    @include media-laptop {
        grid-template-columns: 1.5fr 1fr;
        grid-template-rows: auto;

        grid-template-areas:
            "calculator calculator"
            "chart chart";
    }

    @include media-mobile {
        display: flex;
        flex-direction: column;
    }
}

.container__calculator {
    grid-area: calculator;
}

.container__expected-amount {
    grid-area: expected-amount;
}

.container__expected-yield {
    grid-area: expected-yield;
}

.container__chart {
    grid-area: chart;
    display: flex;
    flex-direction: column;
}


.row {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 20px;
    flex-wrap: wrap;
}

.range-block {
    > div {
        margin-bottom: 30px;
    }

    @include media-laptop {
        display: flex;
        gap: 25px;

        > div {
            flex: 1;
            margin-bottom: 20px; 
        }
    }

    @include media-mobile {
        > div {
            margin-bottom: -10px; 
        }
    }
}

.range-container {
    flex: 1;
}

.range-label {
    font-size: $font-size--large;
    font-weight: 400;
    color: $color-title-active;
}

.form-large {
    flex: 1.5;
    display: flex;
    align-items: center;
}

.form {
    flex: 1;
    display: flex;
    align-items: center;
}

.form-title {
    font-size: 28px;
    font-weight: 600;
    line-height: 34px;
    letter-spacing: 0px;
    margin-bottom: 20px;
}

.form-label {
    font-size: 14px;
    font-weight: 400;
    color: rgba(30, 32, 33, 0.5);
    white-space: nowrap;

    display: flex;
    align-items: center;
    gap: 6px;
}


.divider {
    width: 150px;
    height: 3px;
    background: $color-green;
    margin: 30px auto;
}

.grey-text {
    font-size: 16px;
    font-weight: 300;
    line-height: 19px;
    color: rgba(162, 168, 173, 1);

    &:last-child {
        margin-bottom: 0;
    }
}

.table-container {
    overflow-x: auto;
    margin-bottom: 30px;
}

.info {
    color: #A2A8AD;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.32px;
}

@media (max-width: 1300px) and (min-width: 1025px) {
    .strategy-button {
        flex-basis: 50%;
    }
}

@include media-laptop {
    .range {
        margin-bottom: 0px;
    }
}

@include media-mobile {
    .form-title {
        margin-top: 28px;
        margin-bottom: 28px;
    }

    .range {
        padding: 0;
        margin-bottom: 10px;
    }

    .form-label {
        margin-bottom: 10px;
    }

    .range-block {
        flex-wrap: wrap;
        margin-bottom: 15px;
    }

    .range-container {
        margin-bottom: 0px;

        .form-label {
            margin-bottom: 0px;
        }
    }

    .range-label {
        font-size: $font-size--normal;
    }

    .grey-text {
        font-size: 14px;
    }
}