@import "~@ui/styles/tools";

.section {
    padding: 4% 8%;
    background: #fff;

    @include media-tablet {
        padding: 4% 16px;
    }

    &__title {
        text-align: center;
        font-size: 48px;
        line-height: 1.2;
        color: #1E2021;

        @include media-tablet {
            font-size: 32px;
        }

        > b {
            color: #20836D;
        }
    }

    &__description {
        margin: 60px auto 0;
        text-align: center;
        max-width: 75ch;
        font-size: 16px;
        color: #1E2021CC;

        @include media-tablet-portrait {
            margin: 24px auto 0;
        }
    }
}

.container {
    display: flex;
    justify-content: center;
    gap: 24px;
    margin-top: 50px;

    @include media-desktop-md {
        gap: 12px;
        margin-top: 40px;
    }

    @include media-tablet-portrait {
        flex-wrap: wrap;
    }
}

.block {
    position: relative;
    width: 400px;
    height: 500px;
    border-radius: 20px;
    display: flex;
    padding: 32px;
    overflow: hidden;

    @include media-desktop-md {
        width: 300px;
        height: 400px;
    }

    @include media-tablet-portrait {
        min-width: 35%;
        width: auto;
        height: 280px;
        flex: 1;
    }

    @include media-mobile {
        width: 100%;
        height: auto;
        min-height: 310px;
        flex: unset;
        padding: 20px;
    }

    &__content {
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: space-between;
        z-index: 1;

        p {
            margin: 0;
        }

        @include media-mobile {
            max-width: 250px;
        }
    }

    &__title {
        font-size: 32px;
        color: #1E2021;
        font-weight: 600;
        text-transform: uppercase;
        padding: 0 !important;

        @include media-desktop-md {
            font-size: 24px;
        }

        @include media-tablet {
            font-size: 20px;
        }

        > b {
            color: #20836D;
            font-weight: 400;
        }
    }

    &__description {
        font-size: 20px;
        line-height: 1;
        color: #1E2021CC;
        text-transform: uppercase;
        font-weight: 400;

        @include media-desktop-md {
            font-size: 18px;
        }

        @include media-tablet {
            font-size: 16px;
        }

        > b {
            color: #20836D;
            font-weight: 400;
        }
    }

    &__value-container {
        display: flex;
        flex-direction: column;

        @include media-tablet {
            justify-content: space-between;
            gap: 4px;
        }
    }

    &__value-description {
        font-size: 40px;
        color: #FFFFFFCC;
        line-height: 1.2;
        font-weight: 500;

        @include media-tablet {
            font-size: 34px;
        }

        @include media-tablet-portrait {
            font-size: 30px;
            text-align: right;
            line-height: 1;
        }

        @include media-mobile {
            text-align: left;
        }
    }

    &__value {
        font-size: 136px;
        line-height: 1;
        color: #10EDA9;
        font-weight: 700;

        @include media-desktop-md {
            font-size: 110px;
        }

        @include media-tablet {
            font-size: 88px;
        }

    }

    &__privilege {
        @include media-tablet-portrait {
            width: 100%;
            height: 175px;
            padding: 20px 32px;
        }

        @include media-mobile {
            padding: 20px;
        }

        .block__content {
            @include media-tablet-portrait {
                flex-direction: row;
            }

            @include media-mobile {
                flex-direction: column;
            }
        }

        .block__title {
            color: #fff;
            font-size: 20px;
            font-weight: 700;

            @include media-desktop-md {
                font-size: 18px;
            }

            @include media-tablet {
                font-size: 16px;
            }

            @include media-tablet {
                max-width: 18ch;
                padding: 10px 0;
            }

            @include media-mobile {
                max-width: unset;
            }
        }
    }

    &__background-image {
        pointer-events: none;
    }
}
