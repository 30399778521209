@import '~@ui/styles/tools';
@import '~@ui/styles/variables';
@import '~@app/styles/variables';

.document-card {
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-template-rows: max-content max-content;

    gap: 16px 12px;

    background-color: #f6f7f8;
    border-radius: 24px;
    padding: 28px;

    border: none;
    text-align: left;
    transition: background-color 0.2s ease-in;

    &--clickable {
        cursor: pointer;
    }

    &:hover, &:active {
        background-color: $color-primary;

        .title {
            color: $color-off-white;
        }

        .description {
            color: $color-off-white;
            opacity: 0.6;
        }

        .download-link {
            &:not(:disabled),
            &:visited:not(:disabled),
            &:active:not(:disabled),
            &:visited:not(:disabled):active {
                text-decoration: none;
                background-color: rgb(255, 255, 255, 0.1);
            }


        }
    }

    &:hover {
        .download-link {
            & > * {
                &:first-child {
                    display: none;
                    visibility: hidden;
                }
    
                &:last-child {
                    display: block;
                    visibility: visible;
                }
            }
        }
    }

    &:active {
        .download-link {
            &:not(:disabled),
            &:visited:not(:disabled),
            &:active:not(:disabled),
            &:visited:not(:disabled):active {
                background-color: rgb(255, 255, 255, 0.76);
            }

            & > * {
                &:first-child {
                    display: block;
                    visibility: visible;
                }
    
                &:last-child {
                    display: none;
                    visibility: hidden;
                }
            }
        }
    }
}

.title {
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;

    color: #1E2021;

    transition: color 0.2s ease-in;
}

.description {
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
    padding: 0;

    color: $color-text-light;
    transition: color 0.2s ease-in;
}

.download-wrapper {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
}

.download-link {
    display: block;
    width: 44px;
    height: 44px;
    padding: 13px;
    margin: 0;

    border: none;
    box-shadow: none;
    cursor: pointer;
    background-color: #20836d1a;
    border-radius: 50%;

    transition: background-color 0.2s ease-in;

    & > * {
        &:last-child {
            display: none;
            visibility: hidden;
        }
    }
}

@include media-tablet {
    .title {
        font-size: 20px;
        line-height: 22px;

        max-width: 25ch;
    }
    
    .description {
        font-size: 16px;
        line-height: 19px;
    }

    .download-link {
        width: 39px;
        height: 39px;

        padding: 10px;
    }
}

@include media-mobile {
    .document-card {
        padding: 20px;
    }
}