@import '~@ui/styles/tools';
@import '~@ui/styles/variables';

@mixin medium-breakpoint {
    @media screen and (max-width: 1750px) {
        @content;
    }
}

@mixin small-breakpoint {
    @media screen and (max-width: 1400px) {
        @content;
    }
}

@mixin xs-small-breakpoint {
    @media screen and (max-width: 1200px) {
        @content;
    }
}

.courses-filter {
    display: flex;
    align-items: center;
}

.dropdown-wrapper {
    display: none;
}

.filter {
    display: flex;
    gap: 10px;
}

.dropdown {
    > div {
        border-width: 1px;
        border-radius: 12px;

        > div {
            height: 48px;   
        }
    }
}

@include medium-breakpoint() {
    .courses-filter-button {
        padding: 8px 15px;

        > span {
            white-space: nowrap;
        }
    }
}

@include small-breakpoint() {
    .filter {
        gap: 5px;
    }

    .courses-filter-button {
        padding: 8px 12px;

        > span {
            font-size: 12px;
        }
    }
}   

@include media-tablet {
    .filter {
        row-gap: 5px;
    }

    .filter {
        display: none;
    }

    .dropdown-wrapper {
        display: block;

        height: 54px;
    }

    .courses-filter {
        margin-bottom: 0;
        display: block;
    }
}