@import "../../../styles/tools";

.container-controls {
    padding: 20px 30px;
    border-top: 1px solid #DFE6E3;
}

.container-controls--mobile {
    padding: 0 10px;
}

.container-controls--no-border {
    border-top: none;
}

.chart {
    :global(.apexcharts-tooltip) {
        position: absolute !important;

        @include media-mobile {
            transform: translateY(calc(100% + 14px)) scale(0.9);

            :global(.apexcharts-tooltip-series-group) {
                padding-bottom: 0 !important;
            }

            :global(.apexcharts-tooltip-title) {
                font-size: 12px !important;
            }

            :global(.apexcharts-tooltip-text) {
                font-size: 12px !important;
            }
        }
    }
}

.chart--default-cursor {
    svg {
        cursor: default !important;
    }
}

.top-axis-label {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 700;
    font-size: 13px;
    color: #5b5c5b;
    height: 13px;
    margin-left: 20px;
    margin-top: 10px;
    margin-bottom: -30px;
    pointer-events: none;
}

.container-legend {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
}

.container-legend--no-wrap {
    flex-wrap: nowrap;
}

.legend-item {
    margin-bottom: 0 !important;
    min-width: 120px !important;
    flex-grow: 1;
}

.container-controls--mobile {
    padding: 6px 10px;
}

@include media-mobile {
    .legend-item {
        flex: 1;
        min-width: 80px;
    }
}

@media (max-width: 410px) {
    .container-legend {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 6px;
        row-gap: 6px;
    }

    .legend-item--mobile {
        min-width: 100% !important;
    }
}
