@import "../../../styles/tools";

.container {
    display: flex;
    flex-direction: row;
}

input[type].input {
    flex: 1;
    height: 48px;
    padding: 13px 16px;
    padding-right: 48px;
    color: $color-title-active;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    background: $color-off-white;
    border: none;
    outline: none;
    border-radius: $border-radius;
    border: 1px solid transparent;
    transition: all 0.25s ease-in-out;

    &:not(:focus) {
        border-bottom-color: #EDEDED;
    }

    &:hover {
        border-color: $color-primary;
    }

    &:focus {
        border-color: $color-primary;
    }

    &::placeholder {
        color: $color-grey-dark;
        transition: all 0.25s ease-in-out;
    }

    &.input--secondary {
        background: $color-background;
    }

    &.input--size-small {
        height: 36px;
        padding: 7px 16px;
        padding-right: 36px;
        border-radius: 8px;
    }

    &.input--wide {
        width: 100%;
    }
}

.icon {
    width: 48px;
    height: 48px;
    margin-left: -48px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.25s ease-in-out;

    &.icon--size-small {
        width: 36px;
        height: 36px;
        margin-left: -36px;
    }
}

.container--active {
    position: relative;
    z-index: 1500;
}

.dropdown-portal-container {
    z-index: 1500;
}

.fade {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(7, 7, 7, 0.28);
    z-index: 999;
    opacity: 0.0;
    pointer-events: none;
    transition: all 0.2s ease-in-out;
}

.fade--active {
    opacity: 1.0;
    pointer-events: all;
}

.dropdown-menu {
    background: $color-off-white;
    padding: 24px 16px;
    border-radius: 12px;
    border: none;
    margin-top: 9px;
    overflow-y: auto;
    overflow-x: hidden;
    box-shadow: none;
    min-width: 420px;
}