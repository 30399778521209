@import '~@ui/styles/tools';
@import "~@app/styles/variables";

.card {
    flex: 1;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    padding: 0 !important;
    gap: 12px;
}

.step {
    position: relative;
    background: $color-page-background;
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 12px !important;
    border-radius: 12px;
    width: 100%;
}

.step__primary {
    padding: 32px !important;

    &::before {
        content: '';
        position: absolute;
        top: calc(50% - 25px);
        left: 0;
        width: 8px;
        height: 50px;
        background: $color-primary;
        border-radius: 5px;
    }

    .text {
        font-size: 26px;
    }

    @include media-tablet {
        .text {
            font-size: 20px;
        }
    }
}

.text {
    color: #000000;
    font-size: 20px;
    flex: 1;

    @include media-tablet {
        font-size: 16px;
    }
}

.id {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $color-off-white;
    width: 36px;
    font-size: 20px;
    aspect-ratio: 1/1;
    border-radius: 50%;
    color: #00000080;

    @include media-tablet {
        width: 30px;
        font-size: 20px;
    }
}