.chart {
    position: relative;

    & > div {
        touch-action: auto !important;
    }
}

.chart__inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
