@import "~@ui/styles/tools";

.info {
  color: #A2A8AD;
  font-size: $font-size--normal;
  font-weight: 400;
  margin-top: 30px;
  max-width: 50%;
}

.container {
    margin-top: 20px;
    margin-bottom: 30px;
    position: relative;
    overflow: hidden;
}

.axis-text {
    color: $color-title-active;
    font-size: 13px;
    font-weight: 300;
    line-height: 16px;
  
    @include media-mobile {
      font-size: 9px;
      line-height: 9px;
    }
  }

  
.tooltip-container {
  background: #fff;
  border: 1px solid #F3F4F5;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.tooltip-text {
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  color: rgba(0, 0, 0, 0.5);
}

.tooltip-primary-text {
  color: black;
}

.axis-point {
  max-width: 80px;
}

.tooltip {
  width: 165px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: 16%;
  background: #458678a6;
  color: #fff;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.3px;
  padding: 10px 0;
  border-radius: 12px;
}

.tooltip-mobile {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: 16%;
  background: #458678a6;
  color: #fff;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;

    &:hover {
      .tooltip-mobile--info {
        opacity: 1;
      }
    }
}

.tooltip-mobile--info {
  position: absolute;
  background: #333333;
  width: 133px;
  bottom: 40px;
  opacity: 0;
  transition: 1s ease;
  color: #fff;
  border-radius: 12px;
  padding: 10px 12px;
  font-size: 10px;
  line-height: 15px;
}

@include media-mobile {
  .info {
    max-width: 100%;
    font-size: $font-size--small;
  }
}