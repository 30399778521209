@import "~@ui/styles/tools";
@import "~@app/styles/variables";

.section {
    padding: 4% 8%;
    background: $color-off-white;

    @include media-tablet {
        padding: 4% 16px;
    }

    &__title {
        font-size: 48px;
        line-height: 1.2;
        color: $color-title-lighter;

        @include media-tablet {
            font-size: 32px;
        }

        > b {
            color: $color-primary;
        }
    }

    &__description {
        margin: 60px auto 0;
        text-align: center;
        max-width: 75ch;
        font-size: 16px;
        color: #1E2021CC;

        @include media-tablet-portrait {
            margin: 24px auto 0;
        }
    }

    &__blocks {
        display: flex;
        gap: 24px;
        margin-top: 50px;

        @include media-tablet-portrait {
            flex-direction: column;
        }
    }
}

.block {
    background: $color-page-background;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    flex: 1;

    p {
        margin: 0;
    }

    &__separator {
        position: relative;

        &-text {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -8px;
            left: calc(50% - 25px);
            background-color: $color-page-background;
            font-size: 14px;
            line-height: 1;
            font-weight: 400;
            color: $color-text-light;
            padding: 0 12px;
            height: 14px;
            width: 50px;
        }

        &-line {
            margin: 0;
            border-top-color: #B7B7B7;
            height: 2px;
        }
    }

    &__header {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 32px;
        flex: 2;

        @include media-tablet {
            gap: 16px;
        }

        @include media-tablet-portrait {
            flex-direction: row-reverse;
            justify-content: left;
            flex: 1;
        }

        @include media-mobile {
            flex-direction: column-reverse;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: 32px;
        padding: 32px;
        flex: 1;

        @include media-desktop-md {
            padding: 32px 16px;
        }

        @include media-tablet {
            gap: 16px;
        }

        @include media-tablet-portrait {
            flex-direction: row;
        }

        @include media-mobile {
            flex-direction: column;
        }
    }

    &__wide {
        flex: 2;

        @include media-desktop-md {
            flex: 1.5;
        }

        @include media-tablet {
            flex: 1;
        }

        .block__image {
            width: 80px;
            height: 80px;

            @include media-tablet {
                height: 56px;
            }

            @include media-tablet-portrait {
                min-width: 68px;
                max-width: 68px;
                height: 68px;
            }
        }
    }

    &__step {
        position: absolute;
        top: -15px;
        left: calc(50% - 35px);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 70px;
        height: 30px;
        font-size: 16px;
        line-height: 1;
        font-weight: 600;
        color: $color-title-lighter;
        background-color: $color-off-white;
        padding: 4px 10px;
        border: 1px solid #D6D6D6;
        border-radius: 30px;
    }

    &__image {
        position: relative;
        height: 175px;
        width: 100%;

        img {
            object-fit: contain;
        }

        @include media-desktop-md {
            height: 160px;
        }

        @include media-tablet-portrait {
            min-width: 68px;
            max-width: 68px;
            height: 68px;
        }
    }

    &__title {
        font-size: 20px;
        line-height: 1.2;
        color: $color-title-lighter;
        font-weight: 400;
        text-align: center;
        max-width: 37ch;

        @include media-desktop-md {
            font-size: 16px;
        }

        @include media-tablet {
            max-width: 25ch;
        }

        @include media-tablet-portrait {
            text-align: left;
        }

        @include media-mobile {
            text-align: center;
            max-width: 32ch;
        }
    }

    &__description {
        display: flex;
        align-items: flex-end;
        text-align: center;
        max-width: 58ch;
        flex: 1;

        > p {
            color: $color-text-light;
            font-size: 14px;
            line-height: 1.2;
            font-weight: 400;

            b {
                font-weight: 400;
                color: $color-primary;
            }
        }

        @include media-tablet-portrait {
            text-align: left;
        }

        @include media-mobile {
            text-align: center;
            max-width: 48ch;
        }
    }
}