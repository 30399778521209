@import "~@ui/styles/tools";

.container {
    width: calc(100% - 4px);
    flex: 1;
    display: flex;
    justify-content: center;
    position: relative;
    overflow: hidden;
    margin-left: -14px;
    margin-right: -14px;
}

.content {
    aspect-ratio: 2 / 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 0;
    padding-top: 32px;
    margin: 0 auto;
}

.title {
    color: #FFFFFF;
    font-weight: 300;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    margin-bottom: 4px;
}

.description {
    color: #20836D;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.5px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 12px;

    @include media-mobile {
        font-size: 16px;
    }
}

.multiplier {
    border-radius: 110px;
    border: 4px solid $color-primary;
    background: #FFF;
    color: $color-primary;
    padding: 18px 30px;
    text-align: center;
    font-size: 74px;
    line-height: 59px;
    font-weight: 600;
    width: fit-content;
    margin: 0 auto;

    small {
        font-size: 48px;
        font-weight: 500;
        vertical-align: bottom;
        margin-right: 2px;
    }

    @include media-desktop-xm {
        font-size: 64px;
        padding: 9px 30px;

        small {
            font-size: 40px;
        }
    }

    @include media-desktop-md {
        font-size: 44px;
        padding: 7px 20px;
        line-height: 44px;
    }

    @include media-mobile {
        font-size: 40px;
        line-height: 40px;

        small {
            font-size: 28px;
        }
    }
}

.background {
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
}

.background--target {
    background-color: #0BD19442;
}

.background--current {
    width: 90px;
    background-color: #2CAC8D;
}

.ratio--by-width {
    .background--target, .content {
        width: 100%;
        height: auto;
    }
}

.ratio--by-height {
    .background--target {
        @include media-tablet-portrait-up {
            transform: translate(-50%, -50%) scale(2);
        }
    }

    .background--target, .content {
        height: 100%;
        width: auto;
    }
}

@include media-tablet {
    .container {
        margin-left: 0;
        margin-right: 0;
    }

    .background {
        transform: translate(-50%, -100%);
    }

    .content {
        aspect-ratio: 1 / 1;
    }

    .background--current {
        width: 76px;
    }

    .title {
        width: 76px;
        height: 76px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0;
    }
}