.value {
    font-size: 72px;
    font-weight: 600;
    line-height: 1.2;
    color: #454459;
    text-align: center;
}

.description {
    font-size: 40px;
    font-weight: 300;
    line-height: 1.2;
    color: #454459B2;
    text-align: center;
}