@import "~@ui/styles/tools";

.container {
    display: flex;
    flex-direction: row;
    gap: 32px;
    margin-top: 32px;

    @include media-tablet {
        gap: 24px;
    }

    @include media-tablet-portrait {
        flex-direction: column;
    }
}

.block {
    flex: 1;
    border-radius: 12px;
    background: #FFF;
    padding: 32px;

    @include media-tablet {
        padding: 20px;
    }
}

.block__title {
    color: $color-title-active;
    margin-bottom: 24px;
    font-size: 24px;
    line-height: 28px;
    font-weight: 600;
}

.chart {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 64px;

    @include media-tablet-portrait {
        padding-bottom: 52px;
    }
}

.steps {
    
}

.source {
    margin-top: 16px;
    font-size: 16px;
    color: #1E202199;

    span {
        font-weight: 600;
    }

    @include media-tablet-portrait {
        margin-top: 20px;
    }

    @include media-mobile {
        margin-top: 10px;
        margin-bottom: 20px;
    }
}