$transition-animation: transform 0.2s ease-out;

.line {
    transition: $transition-animation;
    will-change: transform;
    position: absolute;
}

.tooltip-container {
    position: absolute;
    border: 1px solid #e3e3e3;
    background: rgba(255,255,255,.96);
    animation: fade-in 0.2s forwards ease-out;
    border-radius: 5px;
    box-shadow: 2px 2px 6px -4px #999;
    font-size: 14px;
    will-change: transform;

    @keyframes fade-in {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
}

.tooltip-title {
    background: #eceff1;
    border-bottom: 1px solid #ddd;
    padding: 6px;
}

.tooltip-content {
    padding: 6px;
    white-space: nowrap;

    &-value {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        margin-bottom: 4px;

        &>div {
            &:nth-child(2) {
                font-weight: 600;
            }
        }
    }

    &-color-marker {
        display: block;
        width: 10px;
        height: 10px;
        border-radius: 100%;
    }
}
