.not-found {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin: 0 auto;
}

.image-wrapper {
    position: relative;

    width: 296px;
    height: 222px;

    margin-bottom: 24px;
}

.title {
    font-size: 24px;
    line-height: 34px;
    font-weight: 500;

    text-align: center;

    margin-bottom: 16px;
}

.description {
    font-size: 16px;
    line-height: 21px;
    font-weight: 500;
    max-width: 38ch;

    text-align: center;

    color: #1c272380;
}
