.card {
    opacity: 1;
    animation: fadeIn 0.2s ease-in;
}

.not-found {
    grid-column: 1 / -1;

    opacity: 1;
    animation: fadeIn 0.2s ease-in;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}