@import "@frontend/jetlend-web-ui/src/styles/tools";
@import "@app/styles/variables";
@import "~@ui/styles/tools";

@mixin medium-breakpoint {
    @media screen and (max-width: 1650px) {
        @content;
    }
}

@mixin small-breakpoint {
    @media screen and (max-width: 1280px) {
        @content;
    }
}

.section {
    padding: 4% 8%;
}

.title {
    color: $color-title-lighter;
    font-size: 48px;
    font-weight: 600;
    max-width: 60%;
    margin: 0;

    @include medium-breakpoint {
        font-size: 40px;
        max-width: 75%;
    }

    @include small-breakpoint {
        font-size: 32px;
    }

    @include media-tablet {
        font-size: 24px;
    }

    @include media-mobile {
        font-size: 26px;
        max-width: 100%;
        width: 100%;
    }
}

.container {
    margin-top: 50px;
    background: $color-off-white;
    border-radius: 24px;
    width: 100%;
    height: 672px;
    position: relative;

    @include media-tablet {
        height: 472px;
    }

    @include media-mobile {
        height: 322px;
    }

    &>div {
        touch-action: auto !important;
    }
}
