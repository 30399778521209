@import "~@ui/styles/tools";

.chart {
    flex: 1;
    margin-bottom: 20px;
}

.tabs {
    border: 2px solid #F5F6F7;
    border-radius: 30px;
    overflow: hidden;
    width: fit-content;
    margin-bottom: 20px;
    display: flex;
    padding: 0 !important;
}

.tab {
    border: none;
    outline: none;
    background: $color-off-white;
    transition: 0.5s ease;

    font-size: $font-size--small;
    color: #1E202180;
    font-weight: 500;
    width: fit-content;
    padding: 10px;
    text-align: center;

    &--active {
        background: #20836D1A;
        color: #1E2021;
        transition: 0.5s ease;
    }

    &:hover {
        cursor: pointer;
        background: #BDE3DC;
    }

    @include media-mobile {
        width: fit-content;
        font-size: $font-size--xsmall;
    }
}