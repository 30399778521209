@import "~@ui/styles/tools";
@import "../../../../../styles/variables";

@mixin medium-breakpoint {
    @media screen and (max-width: 1650px) {
        @content;
    }
}

@mixin small-breakpoint {
    @media screen and (max-width: 1280px) {
        @content;
    }
}
.container {
    position: relative;
    overflow-x: hidden;

    :global(.react-slideshow-container) {

        button {
            &:disabled {
                display: none;
            }
        }

    }
}

.item-wrapper {
    margin: 0 20px;
}

.slider {
    & > div {
        display: flex;

        &:hover {
            cursor: pointer;
        }
    }
}

.arrow-wrapper {
    height: 50px;
    width: 50px;
    border:none;
    border-radius: 50%;
    background-color: #1e2021;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    top: 50%;
    padding: 0;
    z-index: 2;

    transition: background-color 0.3s ease-in;

    &:hover {
        background-color: #363a3c;

    }

    &--left {
        transform: translateY(-50%) translateX(calc(-100% - 8px)) rotate(180deg)  ;
        right: 0;
    }

    &--right {
        transform: translateY(-50%);
        right: 0;
    }

    &--hidden {
        display: none;
        visibility: hidden;
    }
}

@include small-breakpoint {
    .item-wrapper {
        margin: 0 10px;
    }
}

@include media-tablet {
    .container {
        & > .slim {
            & > div > ul {
                margin-left: 20%;
            }
        }
    }

    .item-wrapper {
        margin: 0 5px;
        height: 100%;
    }

    .slider {
        // The reason is a bug: with infinity = false and slidesToShow > 1 it's possible to swipe further than the last slide
        & > div {
            div:nth-child(8) {
                display: none !important;
                width: 0 !important;
            }
            div:nth-child(9) {
                display: none !important;
                width: 0 !important;
            }
            div:nth-child(10) {
                display: none !important;
                width: 0 !important;
            }
        }
    }
}

@media only screen and (max-width: 600px){
    .container {
        & > .slim {
            width: max(70%, 282px);
        }
    }
}
