@import "../../styles/tools";

.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 23px 30px;
    overflow: hidden;
}

.value {
    font-size: $font-size-subtitle;
    line-height: $font-size-subtitle*1.5;
    font-weight: 500;
    color: $color-title-active;
    letter-spacing: 0.3px;
    white-space: nowrap;

    &--type-bold {
        font-weight: 600;
    }

    &--type-light {
        font-weight: 400;
    }
}

.title {
    margin-bottom: 10px;
    font-size: $font-size--small;
    line-height: $font-size--small*1.5;
    color: $color-title-active;
    font-weight: 300;
    letter-spacing: 0.3px;

    &--type-light {
        color: #A2A8AD;
        margin-bottom: 6px;
    }

    &--type-bold {
        font-weight: 500;
    }
}

.container--type-success .value {
    color: $color-success;
}

.container--type-danger .value {
    color: $color-error;
}

.container--size-small {
    padding: 15px;

    .value {
        font-size: $font-size--xxlarge;
        line-height: $font-size--xxlarge*1.5;
    }
}

.container--size-smallest {
    padding: 10px;

    .value {
        font-size: $font-size--large;
        line-height: $font-size--large*1.5;
    }
}

.container--size-large {
    .value {
        font-size: $font-size-value;
        line-height: $font-size-value*1.2;
    }
}

.container--size-giant {
    .title {
        font-size: 20px;
    }

    .value {
        font-size: 60px;
        line-height: $font-size-value*2;
    }
}

.container--no-padding {
    padding-left: 0;
    padding-right: 0;
}

.container--no-padding-y {
    padding-top: 0;
    padding-bottom: 0;
}

.container--align-center {
    text-align: center;
}

.container--align-right {
    text-align: right;
}

.container--inverted {
    flex-direction: column-reverse;

    .title {
        margin-bottom: 0;
        margin-top: 10px;
    }
}

@include media-tablet {
    .value {
        font-size: 24px;
        line-height: 30px;
    }
}

@include media-mobile {
    .container {
        padding: 10px 15px;
    }

    .value {
        font-size: $font-size--large;
        line-height: 24px;
    }

    .title {
        margin-bottom: 6px;
        font-size: $font-size--xsmall;
    }

    .container--inverted {
        .title {
            margin-bottom: 0;
            margin-top: 6px;
        }
    }
}