@import "~@ui/styles/tools";

.range-block {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin-bottom: 20px;
}

.form-label {
    font-size: 14px;
    font-weight: 400;
    color: rgba(30, 32, 33, 0.5);
    white-space: nowrap;

    display: flex;
    align-items: center;
    gap: 6px;
}

.property-container {
    padding: 0 15px;
    background: #F6F7F8;
    border-radius: 24px;
}

.properties-label {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0px;
    color: rgba(30, 32, 33, 1);
    white-space: nowrap;

    & .properties-label-prefix {
        color: rgba(30, 32, 33, 0.4);
    }
}

.properties-label-item {
    white-space: nowrap;
}

.properties-label--grey {
    color: rgba(32, 131, 109, 1);

    & .properties-label-prefix {
        color: rgba(32, 131, 109, 0.4);
    }
}
 
@include media-tablet {
    .properties-block {
        margin-bottom: 20px;
    }

    .properties-label {
        font-size: 18px;
        line-height: 30px;
    }
}

@include media-mobile {
    .property-item {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .form-label {
        margin-bottom: 10px;
    }

    .range-block {
        flex-wrap: wrap;
        margin-bottom: 15px;
        display: flex;
        flex-direction: column;
    }

    .range-label {
        font-size: $font-size--normal;
    }
}