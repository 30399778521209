@import "~@ui/styles/tools";

.button {
    display: flex;
    flex-direction: column;
    flex: 1;
    border-radius: 12px;
    padding: 12px;
    background: #fff;
    border: 1px solid;
    border-color: transparent;
    text-align: left;
    transition: all 0.175s ease-in-out;
    border: 1px solid #E6ECF0;
    
    &:not(:disabled) {
        cursor: pointer;

        &:hover {
            background: darken(#fff, 8%);
        }

        &:active {
            background: darken(#fff, 12%);
            border-color: $color-primary;
        }
    }
}

.disabled {
    pointer-events: none;

    &:hover {
        background: #fff !important;
    }

    &:active {
        background: #fff !important;
        box-shadow: 0px 3px 50px rgba(151, 173, 176, 0.25) !important;
        transform: none !important;
    }
}

.active {
    border: 1px solid #20836D
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    .title {
        flex: 1;
        font-weight: 400;
        font-size: $font-size--small;
        line-height: $font-size--small*1.4;
        color: $color-title-active;

        display: flex;
        align-items: center;
        justify-content: center;
        gap: 6px;

        & img {
            height: 16px;
            width: 16px;
        }

        @include media-tablet {
            font-size: $font-size--normal;
            line-height: $font-size--normal*1.4;
        }
    }

    .icon {
        width: 36px;
        height: 36px;
        margin-top: -6px;
    }
}

.body {
    color: $color-body;
    font-size: $font-size--small;
    line-height: $font-size--small*1.5;
    font-weight: 500;
}

.tooltip {
    height: 20px;
}